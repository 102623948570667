import React from 'react'
import Layout from '../components/layout'
import Banner from '../components/partials/banner'
import { css } from '@emotion/core'
import useScript from '../hooks/useScript';

  
const NewsletterPage = () => {
  
  useScript(
    'https://lucky-pinecone.ck.page/d050164a38/index.js'
  );

  return <Layout title="Get in touch">
    <Banner />

    <div css={css`
      padding: 5rem 3rem;
      display: grid;
      grid-auto-columns: 1fr;
      grid-gap: 2rem;
      justify-content: center;
      justify-items: center;
      grid-template-areas: "image"
                           "heading"
                           "form";

      @media (min-width: 800px) {
        grid-template-areas: "image heading"
                             "image form";
      }
    `}>
      <div css={css`
        background-size: contain;
        background-position: center center;
        justify-content: center;
        min-height: 300px;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-image: url(/gc/master-it.jpg);
        grid-area: image;
        @media (min-width: 800px) {
          background-position: center right;
        }
      `}></div>
      <div css={css``}>
        <h1>Get in touch</h1>
        <p><a href="mailto:dave@mastery.games">Shoot me an email</a> if you: <br/>
          
        ❓ have questions <br/>
        🐞 found a bug <br/>
        💡 have an idea or cool find to share <br/>
        👋 just wanna say hi</p>

        <p>I'm also available for paid consulting if you need my professional help</p>
        <p>—Dave Geddes</p>
      </div>
      
      <div css={css`grid-area: form;`}>
        
      </div>
    </div>

  </Layout>
}

export default NewsletterPage
